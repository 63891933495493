import { trigger } from '@angular/animations';

export const environment = {
    production: true,
    messageLogin: false,
    isTcurvas: true,
    user: 'MQ==',
    password: 'MQ==',
    urlApi: 'https://apiovlaboilgas.sigeenergia.com/APIOV/',
    urlCch: 'https://apiovlaboilgas.sigeenergia.com/APIOVTM/',
    cryptoKey: 'oP3e4v16oXOgxu2zkQ0QpTyt7i0u2gBR',
    cryptoIV: 'EqWImPp2gD7q0dFx',
    companyName: 'Laboil Gas',
    multiapi: false,
    peticionAPIs:[
      {
        "urlApi":"https://apiovlaboilgas.sigeenergia.com/APIOV/",
        "urlCch":"https://apiovlaboilgas.sigeenergia.com/APIOVTM/",
        "cryptoKey": "oP3e4v16oXOgxu2zkQ0QpTyt7i0u2gBR",
        "cryptoIV": "EqWImPp2gD7q0dFx",
        "companyName":"Laboil Gas",
        "IsClienteExistente":false,
        "token": ""
       }
     ],
    companyAddress: 'RICARDO DEL ARCO 16, 1º IZDA 22004 Huesca',
    // companyAddress: '',
    companyEmail: 'laboilenergia@laboil.es',
    companyPhone: '900 103 089',
    urlAvisoLegal: 'https://www.laboilenergia.es/politica-de-privacidad-y-aviso-legal/#',
    urlCookies: 'https://www.laboilenergia.es/politica-de-cookies/',
    urlUso: 'https://www.laboilenergia.es/politica-de-privacidad-y-aviso-legal/',
    urlPoliticaPrivacidad: "",
    UserAccess: 'DNI/CIF/NIF/EMAIL',
    TextoInicioSesion: 'INICIO DE SESIÓN',
    HabilitarTPV: false,
    iconoresumennuevalectura: 'fab fa-searchengin fa-4x',
    iconoresumendatospago: 'far fa-credit-card-blank fa-4x',
    iconoresumenconsulta: 'far fa-exclamation-square fa-4x',
    alinearmenusuperior: 'izquierda', // derecha izquierda o centro
    MostrarConsumosPorHora: false,
    idiomas: true,
    email: 'laboilenergia@laboil.es',
    comparativa: true, //Gráfico de resumen comparativa importes facturas
    historico: true, //Gráfico de resumen histórico importes facturas
    MostrarPerfilFacturacion: false,
    MostrarFacturaExcel: false,
    DisabledBotonModificarDatos:false,//Botón modificardatos
    DisabledBotonRealizarConsulta:false,//Botón realizar consulta
    DisabledBotonNuevaLectura:false, //Botón realizar nueva lectura
    MostrarTarifaGrupo:false,
    RegistrarAceptacionDeUso: true,
    VersionCondicionesUso: "V1",
    MostrarPreciosContrato: true,
    TiempoEsperaGraficos: '500',
    cchWitchCups: false,
    CambiaTituloCChGraph: false,
    DialogFacturasPendientePago: false,
    MostrarPerfilContratoSoloUnaPotenciaDHA: false,
    agrupacontratoscups: false,
    MostrarDescargaMasivaFacturasPorCups: true,
    internalApiUrl: '',
    mesesgraficocch: 1,
    maxCUPSgraficoCCH: 150,
    MostrarBotonModificiacionDatosPerfil: false,
    MostrarBotonFacturasImpagadas: false,
    MostrarDeuda: false,
    PrefijoNumeracionFacturas: 'C210',
    PrefijoNumeracionContratos: 'C210',
    GraficoConecta2: false,
    MostrarReactivaComparativaConsumos : false,
    OcultaTipoContacto1: 'T',
    UltimasFacturasResumen: 2,
    DobleGraficoConsumosPorHora: false,
    mostrarFechaVencimientoFactura: true,
    mostrarLimitePagoFactura: false,
    _URL_ : "https://ov.laboigas.es",
    messageRecuperarPass: false,
    MostrarAdministradorListadoContratoFechaAlta: true,
  MostrarAdministradorListadoContratoFechaVto: true,
  MostrarAdministradorListadoContratoFechaBaja: true,
  MostrarAdministradorListadoContratoEntorno: true,
  MostrarListadoContratoDireccionSuministro:false,
  MostrarConsumosPorHoraClientes: false,
  MostrarPerfilContratoColectivo: true,
  UnirPerfilDatosEnvioYContacto: false,
  MostrarDatosContratoEnPerfil: false,
  marca: '',
  ApiCallContratosListaElekluz: false,
  defaultLang:'es',
  MostrarDescargaMasivaFacturasBotonExcel: true,
  MostrarDescargaMasivaFacturasFiltroCif: false,
  MostrarDescargaMasivaFacturasFiltroImporte: false,
  MostrarDescargaMasivaFacturasFiltroTarifa: false,
  MostrarListadoContratoDenominacionCliente: false,
  MostrarResumenGraficosDeMultipuntoAAdministradorFincas: false,
  OcultarFacturasConecta2DeAntesDel31x11x19: true,
  MostrarListadoContratoBotonGraficos: false,
  CambiarTitularElekluz: false,
  BotonElekluz: false,
  OcultarContactosEleia: false,
  SimplificacionSerieFactura:false,
  urlPagininaInicial:"",
  MensajeCCHConExplicacion:true,
  EleiaNombreAdministradorFincas: false,
  MostrarRefCatastral: false,
  MostrarCierreSesion: true,
  DesarrolloCatgas: false,
  logindirectoenB64:true,
  MostrarCambioPass:true,
  DesarrolloBonarea: false,
  TextoLegal: "",
ObligarCambioContrasena: true,
  primerLogin: false,
  redirigirPrimerLogin: false,
  mensajeCambioPasswordELeia: false,
  eneluzEspec: false,
  };
